import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import * as styles from './scholarships.module.scss';
import Layout from '../components/Layout';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { IoFilterCircleOutline } from '@react-icons/all-files/io5/IoFilterCircleOutline';
import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';
import { RiCheckboxCircleFill } from '@react-icons/all-files/ri/RiCheckboxCircleFill';

const Scholarships = ({ data }) => {
    const [levels, setLevels] = useState([
        {
            name: 'Undergraduate Degrees',
            state: false,
            id: 0
        },
        {
            name: 'Professional Certifications',
            state: false,
            id: 1
        }
    ]);
    const [types, setTypes] = useState([
        {
            name: 'Fellowship',
            include: 'Fellowship',
            state: false,
            id: 0
        },
        {
            name: 'Scholarship',
            include: 'Scholarship',
            state: false,
            id: 1
        }
    ]);
    const [subjects, setSubjects] = useState([
        {
            name: 'Computer Science',
            include: 'Computer',
            state: false,
            id: 0
        },
        {
            name: 'Software Engineering',
            include: 'Software',
            state: false,
            id: 1
        },
        {
            name: 'Data Analytics',
            include: 'Data',
            state: false,
            id: 2
        }
    ]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [hasRan, setHasRan] = useState(false);
    const [screen, setScreen] = useState(null);

    const getScholarships = () => {
        var newArr = [...data.allScholarshipsJson.nodes];

        return checkFilters(newArr);
    };

    const checkFilters = (scholarships) => {
        var newArr = [];
        var levelStates = [];
        var typeStates = [];
        var subjectStates = [];

        // CHECK LEVELS
        levels.forEach((level) => {
            if (level.state) {
                newArr = newArr.concat(
                    scholarships.filter((item) =>
                        level.name.toLowerCase().includes(item.level)
                    )
                );

                levelStates.push(true);
            }
        });

        // CHECK TYPES
        types.forEach((type) => {
            if (type.state) {
                if (newArr.length === 0) {
                    newArr = newArr.concat(
                        scholarships.filter((item) =>
                            item.details.type.includes(type.include)
                        )
                    );
                } else {
                    newArr = newArr.filter((item) =>
                        item.details.type.includes(type.include)
                    );
                }

                typeStates.push(true);
            }
        });

        // CHECK SUBJECTS
        subjects.forEach((subject) => {
            if (subject.state) {
                if (newArr.length === 0) {
                    if (levelStates.length === 0 && typeStates.length === 0)
                        newArr = newArr.concat(
                            scholarships.filter((item) =>
                                item.courses.find((course) =>
                                    course.title.includes(subject.include)
                                )
                            )
                        );
                } else {
                    newArr = newArr.filter((item) =>
                        item.courses.find((course) =>
                            course.title.includes(subject.include)
                        )
                    );
                }

                subjectStates.push(true);
            }
        });

        // CHECK IF ALL FILTERS UNCHECKED
        if (
            levelStates.length === 0 &&
            typeStates.length === 0 &&
            subjectStates.length === 0
        ) {
            return scholarships;
        }

        return newArr;
    };

    const handleCheckBox = (category, number) => {
        if (category === 'levels') {
            var newArr = [...levels];
            newArr[number].state = !newArr[number].state;
            setLevels(newArr);
        } else if (category === 'types') {
            var newArr = [...types];
            newArr[number].state = !newArr[number].state;
            setTypes(newArr);
        } else if (category === 'subjects') {
            var newArr = [...subjects];
            newArr.forEach((item) => {
                if (item.id === number) {
                    item.state = !item.state;
                } else {
                    item.state = false;
                }
            });
            setSubjects(newArr);
        }
    };

    const getLevel = (level) => {
        return level === 'undergraduate'
            ? 'UNDERGRADUATE'
            : 'PROFESSIONAL CERTIFICATION';
    };

    const handleResize = () => {
        setScreen(getScreenSize());
    };

    const updateScreenSize = () => {
        setScreen(getScreenSize());
    };

    const getScreenSize = () => {
        const width = window.innerWidth;

        if (width >= 1300) {
            return 'xl';
        } else if (width >= 992 && width < 1300) {
            return 'large';
        } else if (width >= 600 && width < 992) {
            return 'small';
        } else {
            return 'xs';
        }
    };

    const handleFilterToggle = () => {
        if (filterOpen) {
            // filter is open, so need to close it back

            setFilterOpen(false);
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        } else {
            // filter is closed, so need to show it

            setFilterOpen(true);
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }
    };

    useEffect(() => {
        if (!hasRan) {
            setHasRan(true);
            updateScreenSize();
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleCheckBox]);

    return (
        <Layout darkBg={true}>
            <div className={styles.scholarshipsContainer}>
                <div className={styles.headerContainer}>
                    <div className={styles.bg}>
                        <StaticImage
                            src="../images/scholarships-header.jpeg"
                            alt="Working People"
                            className={styles.bgImg}
                            objectPosition="center"
                        />
                        <div className={styles.opacity}>
                            <h1>Our Scholarships</h1>
                        </div>
                    </div>
                </div>

                <div className={styles.filterContainer}>
                    <div className={styles.filters}>
                        <div
                            className={styles.filterOpenBtn}
                            onClick={() => handleFilterToggle()}
                        >
                            <IoFilterCircleOutline className={styles.icon} />{' '}
                            Filter
                        </div>

                        <div
                            className={
                                !filterOpen
                                    ? styles.filterBoxClose
                                    : styles.filterBox
                            }
                        >
                            <div className={styles.filterTopContainer}>
                                <h5>
                                    Filter
                                    <span>
                                        ( {getScholarships().length}{' '}
                                        scholarships )
                                    </span>
                                </h5>

                                <div
                                    className={styles.close}
                                    onClick={() => handleFilterToggle()}
                                >
                                    Done
                                </div>
                            </div>

                            <div className={styles.filterCategories}>
                                <div className={styles.filterCategory}>
                                    <h5>Level</h5>

                                    {levels.map((level) => {
                                        return (
                                            <div
                                                className={styles.filterItem}
                                                onClick={() =>
                                                    handleCheckBox(
                                                        'levels',
                                                        level.id
                                                    )
                                                }
                                            >
                                                <div
                                                    className={
                                                        levels[level.id].state
                                                            ? styles.checked
                                                            : styles.check
                                                    }
                                                >
                                                    <FaCheck
                                                        className={styles.icon}
                                                    />
                                                </div>
                                                {level.name}
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className={styles.filterCategory}>
                                    <h5>Type</h5>

                                    {types.map((type) => {
                                        return (
                                            <div
                                                className={styles.filterItem}
                                                onClick={() =>
                                                    handleCheckBox(
                                                        'types',
                                                        type.id
                                                    )
                                                }
                                            >
                                                <div
                                                    className={
                                                        types[type.id].state
                                                            ? styles.checked
                                                            : styles.check
                                                    }
                                                >
                                                    <FaCheck
                                                        className={styles.icon}
                                                    />
                                                </div>
                                                {type.name}
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className={styles.filterCategory}>
                                    <h5>Subject</h5>

                                    {subjects.map((subject) => {
                                        return (
                                            <div
                                                className={styles.filterItem}
                                                onClick={() =>
                                                    handleCheckBox(
                                                        'subjects',
                                                        subject.id
                                                    )
                                                }
                                            >
                                                <div
                                                    className={`
                                                    ${
                                                        subjects[subject.id]
                                                            .state
                                                            ? styles.checked
                                                            : styles.check
                                                    }

                                                        ${styles.radioButton}
                                                    `}
                                                >
                                                    &#9679;
                                                </div>
                                                {subject.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.scholarshipsList}>
                        {getScholarships().map((scholarship) => {
                            return (
                                <Link
                                    to={
                                        scholarship.slug ===
                                        'neumann-fellowship'
                                            ? '/programme/' + scholarship.slug
                                            : '/scholarship/' + scholarship.slug
                                    }
                                    style={{
                                        textDecoration: 'none',
                                        color: 'black'
                                    }}
                                    className={styles.scholarshipItem}
                                >
                                    <div className={styles.imageContainer}>
                                        <GatsbyImage
                                            alt={scholarship.name}
                                            image={getImage(
                                                scholarship.smallImgURL
                                            )}
                                            className={styles.image}
                                            imgStyle={
                                                screen === 'xl' ||
                                                screen === 'large'
                                                    ? {
                                                          borderTopLeftRadius:
                                                              '10px',
                                                          borderBottomLeftRadius:
                                                              '10px'
                                                      }
                                                    : {
                                                          borderTopLeftRadius:
                                                              '10px',
                                                          borderTopRightRadius:
                                                              '10px'
                                                      }
                                            }
                                            placeholder="blurred"
                                        />
                                    </div>

                                    <div className={styles.texts}>
                                        <span>
                                            {getLevel(scholarship.level)}
                                        </span>
                                        <h6>{scholarship.name}</h6>

                                        {screen !== 'xl' ? null : (
                                            <p>
                                                {scholarship.shortDescription}
                                            </p>
                                        )}

                                        <Link className={styles.link} to="">
                                            Learn more
                                            <HiArrowNarrowRight
                                                className={styles.icon}
                                            />
                                        </Link>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Scholarships;

export const ScholarshipsListQuery = graphql`
    query {
        allScholarshipsJson {
            nodes {
                name
                level
                shortDescription
                slug
                smallImgURL {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
                details {
                    type
                }
                courses {
                    title
                }
            }
        }
    }
`;
