// extracted by mini-css-extract-plugin
export var scholarshipsContainer = "scholarships-module--scholarshipsContainer--3pzu9";
export var link = "scholarships-module--link--10tv4";
export var icon = "scholarships-module--icon--p6H4n";
export var headerContainer = "scholarships-module--headerContainer--n9EhH";
export var bg = "scholarships-module--bg--3R_rR";
export var bgImg = "scholarships-module--bgImg--1ujJ3";
export var opacity = "scholarships-module--opacity--_3cLk";
export var filterContainer = "scholarships-module--filterContainer--2Rb3i";
export var filters = "scholarships-module--filters--209o0";
export var filterCategories = "scholarships-module--filterCategories--2tAWG";
export var filterOpenBtn = "scholarships-module--filterOpenBtn--1YGSO";
export var filterTopContainer = "scholarships-module--filterTopContainer--j92MA";
export var filterCategory = "scholarships-module--filterCategory--1LKWq";
export var filterItem = "scholarships-module--filterItem--3Lge7";
export var check = "scholarships-module--check--1F5ps";
export var checked = "scholarships-module--checked--2Lckf";
export var radioButton = "scholarships-module--radioButton--2eRvk";
export var scholarshipsList = "scholarships-module--scholarshipsList--3sZ2O";
export var scholarshipItem = "scholarships-module--scholarshipItem--2yx7L";
export var imageContainer = "scholarships-module--imageContainer--396XK";
export var image = "scholarships-module--image--32zno";
export var texts = "scholarships-module--texts--1HbHC";
export var buttons = "scholarships-module--buttons--2xDgt";
export var blueBtn = "scholarships-module--blueBtn--9cngy";
export var filterBox = "scholarships-module--filterBox--SulKl";
export var filterBoxClose = "scholarships-module--filterBoxClose--1_hOF";
export var close = "scholarships-module--close--3VGqs";